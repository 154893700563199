@font-face {
  font-family: Shippori Mincho B1;
  font-style: normal;
  font-weight: 400;
  src: url("../shippori-mincho-b1-v10-latin-regular.987855f0.woff2") format("woff2");
}

@font-face {
  font-family: Shippori Mincho B1;
  font-style: normal;
  font-weight: 500;
  src: url("../shippori-mincho-b1-v10-latin-500.d18cc405.woff2") format("woff2");
}

@font-face {
  font-family: Shippori Mincho B1;
  font-style: normal;
  font-weight: 600;
  src: url("../shippori-mincho-b1-v10-latin-600.2d0874eb.woff2") format("woff2");
}

@font-face {
  font-family: Shippori Mincho B1;
  font-style: normal;
  font-weight: 700;
  src: url("../shippori-mincho-b1-v10-latin-700.1e1efea4.woff2") format("woff2");
}

@font-face {
  font-family: Shippori Mincho B1;
  font-style: normal;
  font-weight: 800;
  src: url("../shippori-mincho-b1-v10-latin-800.9cf83e56.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 200;
  src: url("../nunito-v16-latin-200.5e865b81.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 200;
  src: url("../nunito-v16-latin-200italic.2d7a77b2.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  src: url("../nunito-v16-latin-300.506a9c7f.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 300;
  src: url("../nunito-v16-latin-300italic.e8b6961a.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  src: url("../nunito-v16-latin-regular.0e479892.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 400;
  src: url("../nunito-v16-latin-italic.71830aac.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  src: url("../nunito-v16-latin-600.a9709a91.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 600;
  src: url("../nunito-v16-latin-600italic.6f95ba59.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  src: url("../nunito-v16-latin-700.6f48fe8c.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 700;
  src: url("../nunito-v16-latin-700italic.3b14f084.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  src: url("../nunito-v16-latin-800.66664364.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 800;
  src: url("../nunito-v16-latin-800italic.4c384f3a.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  src: url("../nunito-v16-latin-900.5b49a3f9.woff2") format("woff2");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 900;
  src: url("../nunito-v16-latin-900italic.b97b1705.woff2") format("woff2");
}

/*# sourceMappingURL=fonts.85c68bf8.css.map */
